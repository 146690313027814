<template>
<v-app class="vs_inner">
    <div v-if="loadingStatusTree">
        <Loader />
    </div>

    <v-row align="center">
        <EditTitle />
        <AddTitle />

        <v-col>
            <v-col class="animation ml-10">
                <LottieAnimation path="document_check.json" loop="false" speed="1.5" />
            </v-col>
        </v-col>

        <v-col class="library_col ml-10 mr-10">
            <v-col>
                <h1 class="library title mb-10">Library</h1>
                <v-btn color="light-blue darken-4" class="ml-2 mt-5 uploadBtn white--text" @click="$store.commit('setAddModal')">
                    + New Title
                </v-btn>
            </v-col>
            <v-container class="pa-10">
                <v-row class="mt-0">
                    <v-hover v-slot="{ hover }">
                        <v-text-field class="ml-0 mb-5" v-model="search" label="Search" filled rounded dense hide-details clearable clear-icon="mdi-close-circle-outline" :style="{
                  'background-color': hover ? 'white' : 'white',
                }">
                        </v-text-field>
                    </v-hover>
                </v-row>

                <v-treeview open-all class="mx-auto mt-5 treeviewer scrollable" rounded transition item-key="title" :items="items" :search="search" :filter="filter">
                    <template slot="label" slot-scope="props">
                        <router-link @click.native="selectProof(props.item)" class="item_title routerLink" :to="'/pdfworkflow'" v-if="props.item.proofURL" :disabled="locked" :class="{ 'disabled': locked }">
                            <span>{{ props.item.proofFileName }}</span>
                            <v-chip color="rgba(94,140,148)" class="white--text tag-chip ml-2"><span>{{ props.item.tag }}
                                </span></v-chip>
                        </router-link>
                        <span class="item_title" color="purple lighten-4" v-else>
                            {{ props.item.title }}</span>

                        <v-icon v-if="props.item.proofURL && locked">mdi-lock</v-icon>

                        <v-icon @click="
                $store.commit('setAddModalVersion', {
                  title: props.item.title,
                  isbn: props.item.isbn,
                })
              " class="ml-2 mb-1 item_title" color="purple darken-4" v-if="props.item.title != 'Titles' && !props.item.proofURL">mdi-plus
                        </v-icon>

                    </template>

                    <template v-slot:prepend="{ item, open }">
                        <v-icon v-if="!item.proofURL">
                            {{ open ? "mdi-folder-open" : "mdi-folder" }}
                        </v-icon>
                        <v-icon v-else>
                            {{ "mdi-file-document-outline" }}
                        </v-icon>
                    </template>

                    <template v-slot:append="{ item }">
                        <!-- edit title btn -->

                        <v-icon class="trash" color="grey darken-2" v-if="item.proofURL" @click="$store.commit('setEditBook', item)">
                            mdi-cog</v-icon>

                        <v-icon color="grey darken-2" class="trash" v-if="item.proofURL" @click="$store.dispatch('deleteBook', item)">
                            mdi-trash-can-outline</v-icon>

                        <!-- delete title btn -->
                    </template>
                </v-treeview>
            </v-container>
        </v-col>
    </v-row>
</v-app>
</template>

<script>
import EditTitle from "./EditTitle.vue";
import AddTitle from "./AddTitle.vue";
import Loader from "./Loader.vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs

import {
    mapState,
    mapGetters
} from "vuex";

export default {
    components: {
        EditTitle,
        AddTitle,
        Loader,
        LottieAnimation,
    },
    data: () => ({
        files: {
            pdf: "mdi-file-pdf",
            txt: "mdi-file-document-outline",
        },
        showUpload: false,
        search: null,
        locked: false,
    }),
    methods: {
        selectProof(item) {
            this.$store.commit("setPDFBucketPath", item.proofURL);
            this.$store.commit("setSelectedPDF", item);
        },
    },
    computed: {
        // filter the search bar for the three
        filter() {
            return (item, search, textKey) => {
                if (!item.isTitle && !item.isVersionFolder) {
                    let hit = item.title.toLowerCase().indexOf(search.toLowerCase()) > -1;
                    return hit ? true : false;
                }
            };
        },
        items() {
            return this.$store.getters.BookTree;
        },
        ...mapGetters({
            loadingStatusTree: "loadingStatusTree",
        }),
    },

    mounted() {
        this.$store.dispatch("getLinodeBooks");
    },
};
</script>

<style lang="scss" scoped>

.disabled {
  pointer-events: none; /* Disables click events on the link */
  opacity: 0.6; /* Applies a visual indication that the link is disabled */
}


.main-content {
    margin-top: 10% !important;
}

.title {
    font-size: 64px !important;
    font-weight: 100 !important;
    font-family: "Kumbh Sans", sans-serif !important;
}

.logo {
    margin-left: -22px;
}

.item_title {
    font-size: 20px !important;
    font-weight: 200 !important;
    cursor: pointer !important;
    cursor: hand !important;
}

.routerLink {
    text-decoration: none !important;
}

.library_col {
    background-color: FEFBE7 !important;
    font-family: "Kumbh Sans", sans-serif;
    // position: relative !important;
    margin-bottom: 10% !important;
    margin-right: 8% !important;
    height: 55vh !important;
}

.tag-chip {
    font-weight: 400 !important;
}

a {
    cursor: pointer !important;
    cursor: hand !important;
}

.trash {
    font-size: 30px !important;
}

.scrollable {
    overflow-y: scroll !important;
    max-height: 300px;
}

.uploadBtn {
    font-size: 16px !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    // font-weight: 400 !important;

    // font-size: 20px !important;
}

.animation {
    position: relative !important;
    margin-top: 5% !important;
    margin-left: 15% !important;
}

.treeviewer {
    // height: 50vh !important;
}

.v-treeview-node__label {
    overflow: initial;
    text-overflow: initial;
}

.v-navigation-drawer__content {
    overflow-y: auto;
}

.scrollable {
    max-height: 300px;
    overflow-y: auto;
}

.scrollable::-webkit-scrollbar {
    width: 20px;
}

.scrollable::-webkit-scrollbar-track {
    background: white !important;
    height: 10px !important;
}

.scrollable::-webkit-scrollbar-thumb {
    background-color: #d7ccc8;
    border-radius: 10px;
    height: 1px !important;
}

.scrollable::-webkit-scrollbar-thumb:hover {
    background-color: #d7ccc8;
}

v-input__control {
    background-color: white !important;
}
</style>
