<template>
  <v-app class="page">
    <v-scale-transition>
      <v-dialog class="editMenu" v-model="dialog" @keydown.esc="$store.commit('setEditModal')" overlay-opacity="100"
        fullscreen>
        <v-list-item class="mb-n2" />
        <v-card class="settings shrink-element">
          <v-toolbar color="white lighten-2" class="black--text rounded-0">
            <v-toolbar-title class="toptoolbar"> Settings </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="$store.commit('setEditModal')">
              <v-icon class="black--text">mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-col>
            <form @submit.prevent="submit">
              <v-row justify="center">

                <!-- left panel  -->
                <v-col cols="12" md="6">
                  <v-card elevation="24" class="ml-10">
                    <v-list-item>
                      <v-list-item-content>
                        <h2 class="left_title mt-9">
                          <v-icon left class="book_icon_btm mb-2">
                            mdi-bookshelf
                          </v-icon>
                          Title Information
                        </h2>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="item-title mt-7">ISBN or Unique ID</v-list-item-title>
                        <v-combobox ref="titleClear" v-model="book.isbn" single deletable-chips chips outlined solo
                          placeholder="Type here" disabled="true">
                          <template #selection="{ item }">
                            <v-chip class="chip mb-2" :color="chipColor" prepend-icon="mdi-file" @click:close="
                              $refs.titleClear.clearableCallback()
                            ">
                              <v-icon left> mdi-lock </v-icon> {{ item }}
                            </v-chip>
                          </template>
                        </v-combobox>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="item-title">Title Name</v-list-item-title>
                        <v-combobox ref="titleClear" v-model="book.title" single deletable-chips chips outlined solo
                          placeholder="Type here" disabled="true">
                          <template #selection="{ item }">
                            <v-chip class="chip mb-2" :color="chipColor" @click:close="
                              $refs.titleClear.clearableCallback()
                            ">
                              <v-icon left> mdi-lock </v-icon>
                              {{ item }}</v-chip>
                          </template>
                        </v-combobox>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="item-title">Version Tag</v-list-item-title>
                        <v-combobox ref="tagClear" v-model="book.tag" single deletable-chips chips outlined solo
                          placeholder="Type here" disabled="true">
                          <template #selection="{ item }">
                            <v-chip class="chip mb-2" :color="chipColor"
                              @click:close="$refs.tagClear.clearableCallback()">
                              <v-icon left> mdi-lock </v-icon>
                              {{ item }}</v-chip>
                          </template>
                        </v-combobox>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="item-title mb-3">Upload Proof</v-list-item-title>
                        <v-file-input accept=".pdf" label=".pdf" filled prepend-icon="mdi-lock mdi-file"
                          disabled="true"></v-file-input>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="item-title mb-3">Notes</v-list-item-title>
                        <v-textarea class="notes mb-0" v-model="book.notes" filled dense>
                        </v-textarea>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item-action class="mb-3">
                      <v-btn class="toptoolbarbtn mt-n10 white--text" @click="submit" depressed color="grey darken-4">
                        <v-list-item-title class="submitButton_btm">Submit</v-list-item-title>
                      </v-btn>
                    </v-list-item-action>
                  </v-card>
                </v-col>

                <v-divider vertical></v-divider>

                <!-- right panel top-->
                <v-col cols="12" md="6">
                  <v-expansion-panels v-model="panel">
                    <v-expansion-panel v-model="panel">
                      <v-expansion-panel-header>
                        <v-row justify="center">
                          <v-col cols="1">
                            <v-btn color="indigo" fab dark small absolute class="mt-3">
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </v-col>
                          <v-col>
                            <h2 class="section_title ml-n3">
                              Language Settings
                            </h2>
                          </v-col>
                          <v-col> </v-col>
                        </v-row>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-card elevation="24">
                          <v-row>
                            <v-col>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title class="item-title mt-5">English Corpus</v-list-item-title>
                                  <v-radio-group class="ml-2" v-model="book.settings.typos.english" row>
                                    <v-radio label="Merriam Webster" value="en_US" color="indigo" />
                                    <v-radio label="Oxford" value="en_GB" color="indigo" />
                                  </v-radio-group>
                                </v-list-item-content>
                              </v-list-item>
                            </v-col>
                            <v-col>
                              <v-list-item class="custom-cols">
                                <v-list-item-content>
                                  <v-list-item-title class="technical item-title mt-5">Em Dash Padding
                                  </v-list-item-title>
                                  <v-radio-group class="ml-2" v-model="book.settings.typos.emPadding" row>
                                    <v-radio label="Balanced" value="on" color="indigo" />
                                    <v-radio label="None" value="off" color="indigo" />
                                  </v-radio-group>
                                </v-list-item-content>
                              </v-list-item>
                            </v-col>
                            <v-col>
                              <v-list-item class="custom-cols2">
                                <v-list-item-content>
                                  <v-list-item-title class="technical item-title mt-5">Technical Corpus
                                  </v-list-item-title>
                                  <v-radio-group class="ml-2" v-model="
                                    book.settings.typos.check_technical_terms
                                  " row>
                                    <v-radio label="Scientific Terms" value="on" color="indigo" />
                                    <v-radio label="Disabled" value="off" color="indigo" />
                                  </v-radio-group>
                                </v-list-item-content>
                              </v-list-item>
                            </v-col>
                          </v-row>


                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title class="item-title mt-3">Secondary Languages
                              </v-list-item-title>

                              <v-combobox class="second_lang mt-5" solo clearable v-model="book.settings.typos.languages"
                                :items="languages" multiple chips label="Click here to select.">
                                <template v-slot:item="{ item }">
                                  <span style="font-size: 16px">{{ item }}</span>
                                </template>
                              </v-combobox>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title class="mt-0 mb-3 item-title">Upload Word Document</v-list-item-title>
                              <v-file-input class="word mt-5" v-model="wordDoc" accept=".docx" label=".docx" filled
                                prepend-icon="mdi-file-word"></v-file-input>
                            </v-list-item-content>
                          </v-list-item>
                          <v-switch color="indigo" class="ml-5 mt-n5 mb-2" v-model="book.settings.typos.useUploadedDoc"
                            :label="`Uploaded Words [ ${dictionary} ]`"></v-switch>

                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title class="item-title">User Words
                              </v-list-item-title>
                              <v-card-actions>
                                <v-btn class="mt-2 ml-n2 black--text" color="grey lighten-2"
                                  @click="$store.commit('setEditWordModal')" depressed>
                                  <v-icon> mdi-add </v-icon>
                                  + Words
                                </v-btn>
                                <v-list-item-action-text class="ml-5 mt-2">
                                  <h3>
                                    {{ book.settings.typos.wordList.length }}
                                  </h3>
                                </v-list-item-action-text>
                              </v-card-actions>
                              <v-switch color="indigo" class="ml-0 mt-1 mb-2" v-model="book.settings.typos.useCustomWords"
                                :label="`Added Words [ ${custWords} ]`"></v-switch>
                            </v-list-item-content>
                          </v-list-item>
                        </v-card>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- btm right -->
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <v-row justify="center">
                          <v-col cols="1">
                            <v-btn color="indigo" fab dark small absolute class="mt-3">
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </v-col>
                          <v-col>
                            <h2 class="section_title ml-n3">
                              Feature Settings
                            </h2>
                          </v-col>
                          <v-col> </v-col>
                        </v-row>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-card elevation="24">
                          <v-list-item class="mb-3 mr-5">
                            <v-list-item-content>
                              <v-list-item />
                              <v-list-item-title>
                                Duplicates
                              </v-list-item-title>
                              <v-slider append-icon="mdi-magnify-plus-outline" prepend-icon="mdi-magnify-minus-outline"
                                @click:append="
                                  book.settings.typos.dupe_thresh++
                                " @click:prepend="
  book.settings.typos.dupe_thresh--
" v-model="book.settings.typos.dupe_thresh" class="mt-3 ml-0 mr-0" label="Max Frequency" step="1" thumb-label="always"
                                min="2" max="5" :color="color" :thumb-color="thumbColor"
                                :track-color="trackColor"></v-slider>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item class="mr-5">
                            <v-list-item-content>
                              <v-list-item-title> Ladders </v-list-item-title>
                              <v-slider append-icon="mdi-magnify-plus-outline" prepend-icon="mdi-magnify-minus-outline"
                                @click:append="book.settings.ladders++" @click:prepend="book.settings.ladders--"
                                v-model="book.settings.ladders" class="mt-3" label="Min Frequency" step="1"
                                thumb-label="always" min="2" max="5" :color="color" :thumb-color="thumbColor"
                                :track-color="trackColor"></v-slider>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item class="mt-n10 mr-5">
                            <v-list-item-content>
                              <v-list-item />
                              <v-list-item-title> Stacks </v-list-item-title>
                              <v-slider append-icon="mdi-magnify-plus-outline" prepend-icon="mdi-magnify-minus-outline"
                                @click:append="book.settings.stacks++" @click:prepend="book.settings.stacks--"
                                v-model="book.settings.stacks" class="mt-3" label="Min Frequency" step="1"
                                thumb-label="always" min="2" max="5" :color="color" :thumb-color="thumbColor"
                                :track-color="trackColor"></v-slider>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item class="mt-n10 mr-5">
                            <v-list-item-content>
                              <v-list-item />
                              <v-list-item-title>Runts </v-list-item-title>
                              <v-slider append-icon="mdi-magnify-plus-outline" prepend-icon="mdi-magnify-minus-outline"
                                @click:append="book.settings.runts++" @click:prepend="book.settings.runts--"
                                v-model="book.settings.runts" class="mt-3" label="Max Character" step="1"
                                thumb-label="always" min="1" max="10" :color="color" :thumb-color="thumbColor"
                                :track-color="trackColor"></v-slider>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>Widows & Orphans
                              </v-list-item-title>

                              <v-row class="ml-n2 mt-n3 mb-5">
                                <v-col cols="2">
                                  <v-checkbox label="Top Page" color="grey darken-4" v-model="
                                    book.settings.widows_orphans.topCheck
                                  ">
                                  </v-checkbox>
                                </v-col>
                                <v-col>
                                  <v-checkbox label="Bottom Page" color="grey darken-4" v-model="
                                    book.settings.widows_orphans.bottomCheck
                                  ">
                                  </v-checkbox>
                                </v-col>
                              </v-row>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item class="mt-n3" />
                        </v-card>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </form>
          </v-col>
        </v-card>

        <EditWords @editWordsUpdated="updateWords" :settingsWordList="book.settings.typos.wordList" />
      </v-dialog>
    </v-scale-transition>
  </v-app>
</template>

<script>
import EditWords from "./EditWords.vue";

export default {
  components: {
    EditWords,
  },
  data() {
    return {
      // dialog: false,
      chipColor: "indigo lighten-4",
      color: "teal lighten-3",
      thumbColor: "blue-grey",
      trackColor: "teal",
      showWords: false,
      panel: 0,
      languages: [],
      book: {
        isbn: `978-3-16-${Math.random().toString().slice(2, 7)}-0`,
        title: "My Book Title",
        tag: "v1",
        notes: "My first pass.",
        bookID: null,
        settings: {
          typos: {
            dupe_thresh: 2,
            useUploadedDoc: false,
            useCustomWords: false,
            wordList: [],
            useAPI: false,
            english: "en_GB",
            languages: [],
            check_technical_terms: "on",
            emPadding: "off",
          },
          runts: 5,
          widows_orphans: {
            bottomCheck: true,
            topCheck: true,
            topEOL: 5,
          },
          stacks: 3,
          ladders: 2,
        },
      },
      proof: null,
      wordDoc: null,
      showAlert: false,
      errorMessage: "Please upload a proof!",
    };
  },
  methods: {
    // grabs event emmitted from updatewords child component
    updateWords(newWords) {
      let prevWordList = this.book.settings.typos.wordList;
      this.book.settings.typos.wordList = newWords;
    },
    async submit() {
      this.$store.dispatch("editBook", {
        book: this.book,
        wordDoc: this.wordDoc,
      });
    },
  },
  computed: {
    dialog() {
      return this.$store.state.books.showEdit;
    },
    dictionary() {
      return this.book.settings.typos.useUploadedDoc ? "ON" : "OFF";
    },
    custWords() {
      return this.book.settings.typos.useCustomWords ? "ON" : "OFF";
    },
    api() {
      return this.book.settings.typos.useAPI ? "ON" : "OFF";
    },
    docFileLabel() {
      let filename = this.book.docFileName;
      return filename !== null ? filename : ".docx";
    },
  },

  mounted() {
    this.book = this.$store.state.books.editBookData;
  },

  // unbounded state changes
  watch: {
    "$store.state.books.editBookData": function () {
      this.book = this.$store.state.books.editBookData;
      this.languages = this.$store.state.auth.config.Default_Settings.Secondary_Languages
      console.log("change book");
      console.log(this.book);
    },
  },
};

// upload pdf to google cloud
</script>

<style lang="scss" scoped>
.page {
  zoom: unset !important;
  zoom: 0.2 !important;
}

// model
.v-list-item__content {
  padding: 0 !important;
}

.errorMessage {
  font-family: "Kumbh Sans", sans-serif !important;
  font-size: 20px !important;
  font-weight: 300 !important;
}

.toptoolbar {
  font-size: 25px !important;
  font-weight: 300 !important;
  font-family: "Kumbh Sans", sans-serif !important;
}

.toptoolbarbtn {
  font-size: 16px !important;
}

.v-list-item__title {
  font-size: 18px !important;
  font-weight: 300 !important;
  font-family: "Kumbh Sans", sans-serif !important;
  text-decoration: underline;
}

.section_title {
  margin-top: 1.4rem;
  margin-bottom: 0.5rem;
  font-size: 25px !important;
  font-family: "Kumbh Sans", sans-serif !important;
  font-weight: 300 !important;
}

.left_title {
  margin-top: 1.4rem;
  margin-bottom: 0.5rem;
  font-size: 25px !important;
  font-family: "Kumbh Sans", sans-serif !important;
  font-weight: 300 !important;
}

.item-title {
  font-size: 18px !important;
  font-weight: 300 !important;
  font-family: "Kumbh Sans", sans-serif !important;
  text-decoration: underline;
  margin-bottom: 5px !important;
}

.chip {
  font-size: 16px !important;
  font-family: "Kumbh Sans", sans-serif !important;
  font-weight: 400 !important;
  // font-family: Arial, Helvetica, sans-serif !important;
}

.book_icon {
  font-size: 30px !important;
  margin-right: 10px !important;
}

.submitButton_btm {
  // font-size: 18px !important;
  font-weight: 400 !important;
  text-decoration: none !important;
  // // text-transform: none !important;
}

.book_icon_btm {
  font-size: 30px !important;
  margin-right: 10px !important;
}

.notes {
  font-family: "Kumbh Sans", sans-serif !important;
}

.custom-cols {
  margin-left: -50px;
  margin-right: 80px;
}

.custom-cols2 {
  margin-left: -120px;
  margin-right: 100px
}

// .shrink-element {
//   transform: scale(0.85);
// }

.shrink-element {
  transform: scale(1);
  margin-top: -3% !important;
}
</style>
