<template>
  <v-app>
    <v-main id="workflow" class="overflow-y-visible">
      <v-app-bar class="appBar" elevation="6" min-height="80px">
        <v-row align="center" justify="center" class="mt-0 flex-shrink-0">
          <v-col cols="2">
            <router-link class="routerLink" :to="'/bookshelf'">
              <v-icon class="library ml-2 mr-10 mb-0" color="black lighten-3"
                >mdi-bookshelf</v-icon
              >
            </router-link>
          </v-col>
          <v-col>
            <v-img
              width="200px"
              contain
              class="ml-n2 mt-4"
              src="@/assets/beta_logo.png"
            />
          </v-col>
        </v-row>

        <v-col cols="8" />
        <v-row align="center" justify="center" class="mt-1 flex-shrink-0">
          <v-icon>mdi-account</v-icon>
          <v-toolbar-title class="account ml-2 mr-2">
            {{ username }}
          </v-toolbar-title>
          <v-btn
            color="rgba(94,140,148)"
            class="white--text ml-1 logoutBtn"
            @click="logOut"
          >
            Sign Out
          </v-btn>
        </v-row>
      </v-app-bar>

      <v-toolbar
        fixed
        class="mt-0 fixed-bar"
        elevation="4"
        color="grey lighten-5"
      >
        <v-row align="center" class="mb-2 mt-3 ml-n1 flex-nowrap">
          <v-switch
            inset
            class="ml-5 mr-2 mt-5 chip-tag black--text"
            color="deep-purple darken-4"
            v-for="(item, index) in items"
            v-if="config.includes(item.title)"
            :disabled="item.disabled"
            v-model="item.on"
            :key="item.title"
            @click="clickFeature(item, index)"
          >
            <template v-slot:label>
              <v-icon class="mr-2" color="deep-purple darken-4">{{
                item.icon
              }}</v-icon>
              <v-list-item-subtitle class="tag-chip">
                {{ item.title }}
              </v-list-item-subtitle>
            </template>
          </v-switch>

          <v-spacer />

          <router-link class="mb-3 routerLink black--text" :to="'/bookshelf'">
            <v-chip
              color="light-blue darken-4"
              label
              class="tag-chip mr-1 white--text"
            >
              <span> {{ `${selectedBook.title.slice(0,7)}...` }} </span>
            </v-chip>

            <v-chip
              color="deep-purple darken-4"
              label
              class="tag-chip mr-2 white--text"
            >
              <span> {{ selectedBook.tag }} </span>
            </v-chip>

            <v-icon
              class="gear mb-n1 mr-1"
              color="grey darken-4"
              @click="$store.commit('setEditBook', selectedBook)"
            >
              mdi-cog</v-icon
            >
          </router-link>

          <v-icon class="expand mr-2 mt-n1 mr-3" @click="goFullScreen"
            >mdi-arrow-expand-all</v-icon
          >
        </v-row>
      </v-toolbar>
      <PDFViewer />
    </v-main>
  </v-app>
</template>


<script>
import Loader from "../../components/Loader.vue";
import PDFViewer from "../../components/PDFViewer.vue";

import { mapState, mapGetters } from "vuex";

export default {
  components: {
    Loader,
    PDFViewer,
  },
  name: "Nav",
  data: () => ({
    dialog: false,
    isLogout: false,
    fullscreen: false,
    navitemspacing: "pa-1",
  }),

  methods: {
    clickFeature(selection, index) {
      this.$store.dispatch("featureWork", selection.feature);
    },

    goFullScreen() {
      var element = document.querySelector("#workflow");

      if (!this.fullscreen) {
        element
          .requestFullscreen({ navigationUI: "show" })
          .then(function () {
            // element has entered fullscreen mode successfully
          })
          .catch(function (error) {
            // element could not enter fullscreen mode
          });
      } else {
        document
          .exitFullscreen()
          .then(() => console.log("Document Exited from Full screen mode"))
          .catch((err) => console.error(err));
      }

      this.fullscreen = !this.fullscreen;
    },

    async logOut() {
      this.isLogout = true;
      this.$store.dispatch("logOut");
    },
  },

  computed: {
    ...mapState({
      loadingStatus: (state) => state.pdf.loadingStatus,
      buttonStatus: (state) => state.pdf.buttonStatus,
      username: (state) => state.auth.username,
      config: (state) => [...state.auth.config.Features, "User"]
    }),
    ...mapGetters({
      selectedBook: "selectedBook",
      items: "toggleItems",
    }),
  },

  async beforeRouteLeave(to, from, next) {
    this.$store.commit("resetPDFState");
    next();
  },

  mounted() {
    // this.items = this.$store.getters.toggleItems;
  },
};
</script>

<style lang="scss" scoped>
.appBar {
  z-index: 999 !important;
  font-family: "Kumbh Sans", sans-serif !important;
  font-size: 12px !important;
  // font-weight: 400;

  .account {
    font-size: 18px !important;
  }

  .logoutBtn {
    font-weight: 400 !important;
  }
}

.navBar {
  // z-index: 950 !important;
}

.viewer {
  margin-left: -10% !important;
  z-index: 1 !important;
}

.routerLink {
  text-decoration: none !important;
  font-size: 30px !important;
  cursor: pointer !important;
}

.tag-chip {
  font-weight: 400 !important;
  font-size: 14px !important;
}

.fixed-bar {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 0em;
  // z-index: 2;
}

.gear {
  font-size: 28px !important;
}

.library {
  font-size: 30px !important;
}

.expand {
  font-size: 30px !important;
}
</style>

