var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"vs_inner"},[(_vm.loadingStatusTree)?_c('div',[_c('Loader')],1):_vm._e(),_c('v-row',{attrs:{"align":"center"}},[_c('EditTitle'),_c('AddTitle'),_c('v-col',[_c('v-col',{staticClass:"animation ml-10"},[_c('LottieAnimation',{attrs:{"path":"document_check.json","loop":"false","speed":"1.5"}})],1)],1),_c('v-col',{staticClass:"library_col ml-10 mr-10"},[_c('v-col',[_c('h1',{staticClass:"library title mb-10"},[_vm._v("Library")]),_c('v-btn',{staticClass:"ml-2 mt-5 uploadBtn white--text",attrs:{"color":"light-blue darken-4"},on:{"click":function($event){return _vm.$store.commit('setAddModal')}}},[_vm._v(" + New Title ")])],1),_c('v-container',{staticClass:"pa-10"},[_c('v-row',{staticClass:"mt-0"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-text-field',{staticClass:"ml-0 mb-5",style:({
                  'background-color': hover ? 'white' : 'white',
                }),attrs:{"label":"Search","filled":"","rounded":"","dense":"","hide-details":"","clearable":"","clear-icon":"mdi-close-circle-outline"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]}}])})],1),_c('v-treeview',{staticClass:"mx-auto mt-5 treeviewer scrollable",attrs:{"open-all":"","rounded":"","transition":"","item-key":"title","items":_vm.items,"search":_vm.search,"filter":_vm.filter},scopedSlots:_vm._u([{key:"label",fn:function(props){return [(props.item.proofURL)?_c('router-link',{staticClass:"item_title routerLink",class:{ 'disabled': _vm.locked },attrs:{"to":'/pdfworkflow',"disabled":_vm.locked},nativeOn:{"click":function($event){return _vm.selectProof(props.item)}}},[_c('span',[_vm._v(_vm._s(props.item.proofFileName))]),_c('v-chip',{staticClass:"white--text tag-chip ml-2",attrs:{"color":"rgba(94,140,148)"}},[_c('span',[_vm._v(_vm._s(props.item.tag)+" ")])])],1):_c('span',{staticClass:"item_title",attrs:{"color":"purple lighten-4"}},[_vm._v(" "+_vm._s(props.item.title))]),(props.item.proofURL && _vm.locked)?_c('v-icon',[_vm._v("mdi-lock")]):_vm._e(),(props.item.title != 'Titles' && !props.item.proofURL)?_c('v-icon',{staticClass:"ml-2 mb-1 item_title",attrs:{"color":"purple darken-4"},on:{"click":function($event){return _vm.$store.commit('setAddModalVersion', {
                  title: props.item.title,
                  isbn: props.item.isbn,
                })}}},[_vm._v("mdi-plus ")]):_vm._e()]}},{key:"prepend",fn:function(ref){
                var item = ref.item;
                var open = ref.open;
return [(!item.proofURL)?_c('v-icon',[_vm._v(" "+_vm._s(open ? "mdi-folder-open" : "mdi-folder")+" ")]):_c('v-icon',[_vm._v(" "+_vm._s("mdi-file-document-outline")+" ")])]}},{key:"append",fn:function(ref){
                var item = ref.item;
return [(item.proofURL)?_c('v-icon',{staticClass:"trash",attrs:{"color":"grey darken-2"},on:{"click":function($event){return _vm.$store.commit('setEditBook', item)}}},[_vm._v(" mdi-cog")]):_vm._e(),(item.proofURL)?_c('v-icon',{staticClass:"trash",attrs:{"color":"grey darken-2"},on:{"click":function($event){return _vm.$store.dispatch('deleteBook', item)}}},[_vm._v(" mdi-trash-can-outline")]):_vm._e()]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }