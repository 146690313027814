import Vue from 'vue'
import { UploadBookFiles, preProcessBook, getCustomerBooks, deleteBookVersion, editBookVersion } from "../../services/linodeService.js";
import store from '../index.js'


const getDefaultState = () => {
    return {
        customerBooks: {},
        editBookData: {},
        bookProgress: [],
        showBookTree: false,
        showEdit: false,
        showAdd: false,
        showWords: false,
        showWordsEdit: false,
        showWordsAdd: false,
        loadingStatus: false,
        isVersion: false,
        versionTitle: "",
        versionIsbn: ""
    }
}


export default {
    state: getDefaultState(),
    mutations: {
        setLoadingStatus(state) {
            state.loadingStatus = !state.loadingStatus
            console.log(state.loadingStatus)
        },
        setBookTree(state) {
            state.showBookTree = !state.showBookTree
        },
        setCustomerBooks(state, books) {
            state.customerBooks = { ...books }
        },
        setAddModalVersion(state, versionData) {
            const { title, isbn } = versionData
            state.versionTitle = title
            state.versionIsbn = isbn
            state.isVersion = true
            state.showAdd = !state.showAdd
        },
        // add for book tree
        setAddModal(state) {
            state.isVersion = false
            state.showAdd = !state.showAdd
        },
        // add words submodal 
        setAddWordModal(state) {
            state.showWordsAdd = !state.showWordsAdd
        },
        // edit for book tree
        setEditBook(state, selectedBookData) {
            state.showEdit = !state.showEdit
            state.editBookData = { ...selectedBookData }
        },
        setEditModal(state) {
            state.showEdit = !state.showEdit
        },
        // add words submodal 
        setEditWordModal(state) {
            state.showWordsEdit = !state.showWordsEdit
        },
        resetBooksState(state) {
            Object.assign(state, getDefaultState())
        },
    },
    actions: {
        // will reach out to firebase API here
        async getLinodeBooks({ commit, rootState }) {
            let organization = rootState.auth.organization
            commit("setLoadingStatus")
            const CustomerBooks = await getCustomerBooks(organization)
            console.log(CustomerBooks)
            commit("setLoadingStatus")
            commit("setCustomerBooks", CustomerBooks)
            commit("setBookTree")
        },

        // add book 
        async addBook({ commit, rootState }, payload) {
            // closing the add book modal 
            commit("setAddModal")
            // start the loader up
            commit("setLoadingStatus")

            try {
                // uploads book, settings, stylesheet to Linode Volume
                const { book, files } = payload
                const UploadResult = await UploadBookFiles(files, book)
                console.log("successfully uploaded book files")

                // preprocess proof 
                const preProcessRes = await preProcessBook(UploadResult)
                console.log("pre-Processed Book")

                let organization = rootState.auth.organization
                // add book to customer State
                let newBooks = await getCustomerBooks(organization)
                commit("setCustomerBooks", newBooks)
            }

            catch (e) {
                console.log(e)
            }

            commit("setLoadingStatus")
        },

        // delete book 
        async deleteBook({ commit, rootState }, payload) {
            commit("setLoadingStatus")
            const { assetsPath } = payload
            try {
                // delete book
                const organization = rootState.auth.organization
                payload = { "assetsPath": assetsPath, "organization": organization }
                const res = await deleteBookVersion(payload)
                // get new book tree
                const newBooks = await getCustomerBooks(organization)
                commit("setCustomerBooks", newBooks)

            } catch (e) {
                console.log(e)
            }
            commit("setLoadingStatus")
        },

        // edit book 
        async editBook({ commit, rootState }, payload) {
            commit("setEditModal")
            commit("setLoadingStatus")

            try {
                // upload new book info and wordDoc
                await editBookVersion(payload)

                let organization = rootState.auth.organization
                const newBooks = await getCustomerBooks(organization)
                // get new book info 
                commit("setCustomerBooks", newBooks)

            }
            catch (e) {
                console.log(e)
            }

            commit("setLoadingStatus")
        },


    },
    getters: {
        BookTree(state) {
            console.log("getting book tree")
            // root node 
            const bookTree = [{
                title: 'Titles',
                isTitle: true,
                children: []
            }]

            // children
            Object.entries(state.customerBooks).forEach(([title, bookData]) => {
                bookTree[0].children.push({
                    title: title,
                    isbn: bookData[0].isbn,
                    isVersionFolder: true,
                    children: bookData
                })
            })
            console.log(bookTree)
            return bookTree
        },
        loadingStatusTree(state) {
            return state.loadingStatus
        },
    }
}

