<template>
  <v-app>
    <v-main>
      <v-row align="center" justify="center" class="loader" dense>
        <v-col cols="4">
          <!-- <v-icon x-large color="grey darken-2" class="icon"> mdi-magnify-expand </v-icon> -->
          <v-card-subtitle class="working ml-10 mb-0">
            <h1>{{ "Working. Please Wait...." }}</h1>
          </v-card-subtitle>

          <LottieAnimation path="document_scan.json" />

          <!-- <v-progress-linear
            value="50"
            height="50"
            color="purple lighten-4"
            indeterminate
            stream
            buffer-value="10"
          ></v-progress-linear> -->
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Jura&display=swap");

// $vert: 30vh;
// $horz: 15vw;

.loader {
  position: relative;
  top: 18vh !important;
  font-size: 30px !important;
}

h1 {
  font-family: "Kumbh Sans", sans-serif;
  font-size: 30px;
  font-weight: 200;
}

.v-application .primary {
  background-color: #38536d !important;
}

.theme--light.v-list {
  background: #5e9e2f14;
  color: rgba(0, 0, 0, 0.87);
}

.working {
  position: absolute !important;
  margin-left: 7% !important;
  margin-top: 2% !important;
}
</style>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs

export default {
  props: ["message"],
  components: {
    LottieAnimation,
  },
};
</script>
